<template>
  <div />
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import { initialAbility } from '@/libs/acl/config'

export default {
  components: {
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      Result: {
        THG: {
          CloseDateName: '-',
          R1: '-',
          U3: '-',
          U2: '-',
          F3N1: '-',
          F3N2: '-',
          B3N1: '-',
          B3N2: '-',
          D2: '-',
        },
      },
    }
  },
  async mounted() {
    this.ShowWallet()
    const ReThg = await this.GetResult('THG', 'THG')
    if (ReThg) {
      this.Result.THG.CloseDateName = ReThg.RoundData.CloseDateName
      this.Result.THG.R1 = ReThg.R1
      this.Result.THG.U3 = ReThg.U3
      this.Result.THG.U2 = ReThg.U2
      this.Result.THG.F3N1 = ReThg.F3N1
      this.Result.THG.F3N2 = ReThg.F3N2
      this.Result.THG.B3N1 = ReThg.B3N1
      this.Result.THG.B3N2 = ReThg.B3N2
      this.Result.THG.D2 = ReThg.D2
    }
  },
  methods: {
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')
      localStorage.removeItem('CountLogin')

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },
    async ShowWallet() {
      try {
        const { data: response } = await this.$http.get(
          'https://lottothaiapi.linelotto.vip/api/wallet/show',
        )
        if (response && response.success === true) {
          const Main = response.WalletData.MainWallet
          this.$store.state.appConfig.UserData.MainWallet = Main.balance
        } else {
          this.$store.state.appConfig.UserData.MainWallet = 0
        }
      } catch (err) {
        console.log(err)
      }
    },
    // eslint-disable-next-line consistent-return
    async GetResult(LottoHead, LottoSubHead) {
      const params = {
        LottoHead,
        LottoSubHead,
      }
      try {
        const { data: ResData } = await this.$http.get(
          'https://lottothaiapi.linelotto.vip/api/result/showlast',
          { params },
        )

        if (ResData.success) {
          return ResData.mes
        }
        return null
      } catch (e) {
        // console.log(e)
        return null
      }
    },
    Commas(x) {
      if (Math.round(x) !== x && x > 0) {
        const Num = Number(x)
        const y = Num.toFixed(2)
        return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
  },
}
</script>

<style scoped>
.btn-withdraw {
  height: 36px;
  background-color: #ffffff27;
  color: #FFF !important;
  border: 1px solid #fff;
  font-weight: 700;
  padding: 0 6px;
  border-radius: 2px !important;
}
.btn-depo {
  height: 36px;
  background: #FFF;
  border: none;
  font-weight: 700;
  padding: 0 6px;
  border-radius: 2px !important;
}
.bg-balance {
  background-image: url(https://www.ltobet.com/assets/img/bg-banner-home.svg);
  background-repeat: repeat;
  background-size: 100% auto;
}

</style>
